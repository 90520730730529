.credits {
	position: absolute;
	z-index: 1500;

	width: 100%;
	height: 100%;

	background-color: #fbf3eb;

	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;

	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;

	pointer-events: all;

	overflow: hidden;
}

.credits-lang {
	height: 56px;
	background-color: "#EDE0D3";

	right: 0px;
	margin: 24px;

	align-self: flex-start;

	background-color: #ede0d3;

	border-radius: 28px;

	cursor: pointer;
	pointer-events: all;

	display: flex;
	flex-direction: column;
	justify-content: center;
}

.credits-langSelectFlag {
	aspect-ratio: 1/1;
	height: 22px;

	margin: 0 8px 0 16px;

	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
	background-image: url("../../style/assets/splashscreen/flags/fr.png");
}

.credits-langSelectText {
	margin: 10px;

	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	font-family: "Geist";
	font-size: 16px;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: 0.02em;
	text-align: left;

	color: #2b1d16;
}

.credits-langSelectArrow {
	aspect-ratio: 1/1;
	height: 22px;

	margin: 0 16px 0 12px;

	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
	background-image: url("../../style/assets/icons/chevron-down-sharp.svg");

	filter: invert();
}

.credits-content {
	position: absolute;
	width: 100%;
	height: 100%;

	overflow: overlay;

	scroll-behavior: smooth;
}

.credits-body {
	position: absolute;
	width: calc(100% - 64px);

	margin: 0px 32px 0 32px;
}

.credits-bold {
	margin: 24px 32px 0 32px;

	color: #423129;

	font-family: "Geist";
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 24px;
	/* 150% */
	letter-spacing: 0.32px;

	text-align: left;
}

.credits-regular {
	margin: 16px 32px 0 32px;

	color: #423129;

	font-family: "Geist";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	/* 142.857% */
	letter-spacing: 0.28px;

	text-align: left;
}

.credits-title {
	width: calc(100% - 64px);

	margin: 132px 32px 0 32px;

	font-size: min(40px, max(16vw, 16px));
	font-weight: 500;
	font-family: "OrticaAngular-Bold";
	color: #1c1917;
	text-align: left;
}

.credits-subtitle {
	margin: 32px 32px 0 32px;

	color: #423129;

	font-family: "Geist";
	font-size: 24px;
	font-style: normal;
	font-weight: 700;
	line-height: 28px;
	letter-spacing: 0.32px;

	text-align: left;
}

.resetButton {
	width: calc(100 - 48px);
	height: 48px;

	margin: 24px;

	background-color: #ede0d3;
	border-radius: 28px;

	pointer-events: all;

	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	font-family: "Geist";
	font-size: 16px;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: 0.02em;
	text-align: left;

	color: #2b1d16;
}

.credits-geoloc {
	margin: 16px 32px 0 32px;

	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.credits-switch {
	display: flex;
	height: 32px;
	width: 52px;
	background-color: "red";
}

.credits-geoloc-text {
	width: calc(100% - 64px);

	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	text-align: left;
}

.credits-geoloc-textBold {
	color: #423129;

	font-family: "Geist";
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 24px;
	/* 150% */
	letter-spacing: 0.32px;

	text-align: left;
}

.credits-geoloc-textRegular {
	color: #423129;

	font-family: "Geist";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	/* 142.857% */
	letter-spacing: 0.28px;

	text-align: left;
}

.credits-switchOutside {
	position: relative;
	background-color: #EDE0D3;
	height: 32px;
	width: 52px;

	border-radius: 50px;
}

.credits-switchInside {
	position: absolute;

	top: 50%;
	left: 50%;

	transform: translate(-120%, -50%);

	background-color: #765C4E;
	height: 16px;
	width: 16px;

	border-radius: 8px;
}

.credits-switchOutsideDeny {
	position: relative;
	background-color: #ABBC9A;
	height: 32px;
	width: 52px;

	border-radius: 50px;
}

.credits-switchInsideDeny {
	position: absolute;

	top: 50%;
	left: 50%;

	transform: translate(-20%, -50%);

	background-color: #FFFFFF;
	height: 24px;
	width: 24px;

	border-radius: 12px;
}
.ui {
	position: absolute;
	z-index: 600;

	width: 100%;
	height: 100%;
}

.ui-top {
	position: absolute;
	width: 100%;
	height: 100px;

	top: 0%;

	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.ui-top-button-shadow {
	box-shadow: 0px 6px 6px 0px #46302541;
	box-shadow: 0px 4px 4px 0px #46302541;
}

.ui-top-button {
	aspect-ratio: 1/1;
	width: 56px;

	margin: 24px;

	background-color: #ede0d3;
	border-radius: 50%;

	pointer-events: all;
}

.ui-top-button-help {
	background-repeat: no-repeat;
	background-size: 32px;
	background-position: center;
	background-image: url("../../style/assets/icons/help.svg");
}

.ui-top-button-back {
	width: 48px;
	background-color: "#EDE0D3";

	align-self: flex-start;

	background-repeat: no-repeat;
	background-size: 16px;
	background-position: center;
	background-image: url("../../style/assets/icons/arrow-right.svg");

	transform: scaleX(-1);
}

.ui-top-button-target {
	background-repeat: no-repeat;
	background-size: 32px;
	background-position: center;
	background-image: url("../../style/assets/icons/target.svg");
}

.ui-bottom {
	position: absolute;
	width: 100%;
	height: 100px;

	bottom: 0%;

	pointer-events: all;
}

.ui-bottom-2 {
	position: absolute;
	z-index: 2;
	height: 100%;
	width: 100%;

	bottom: 0%;
	left: 0%;

	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	gap: 16px;
}

.ui-bottom-button {
	height: 36px;
	width: min(80px, 25%);

	line-height: min(16px, max(8vw, 8px));
	font-size: min(16px, max(8vw, 8px));
	font-weight: 500;
	color: #2b1d1699;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	background-color: #fdfaf7;

	border: 1px solid #8a564931;

	border-radius: 20px;

	filter: drop-shadow(0px 2px 2px #8a56494d);
}

.ui-bottom-button-selected {
	margin: 0 0 0 0;

	color: #FDFAF7;

	background-color: #2b1d16;
}

.privatePolicy {
	position: absolute;
	z-index: 20;

	width: 100%;
	height: 100%;

	background-color: #FBF3EB;

	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;

	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;

	pointer-events: all;

	overflow: hidden;
}

/* Content */
.privatePolicy-content {
	position: absolute;
	width: 100%;
    height: 100%;

	overflow: overlay;

	scroll-behavior: smooth;
}

.privatePolicy-body {
	position: absolute;
	width: calc(100% - 64px);

	margin: 0px 32px 0 32px;
}

.privatePolicy-bold {
    margin: 32px 0 0 0;

	color: #423129;

	font-family: "Geist";
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 24px; /* 150% */
	letter-spacing: 0.32px;

    text-align: left;
}

.privatePolicy-regular {
    margin: 16px 0 0 0;

	color: #423129;

	font-family: "Geist";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px; /* 142.857% */
	letter-spacing: 0.28px;

    text-align: left;
}

.privatePolicy-title {
	width: calc(100% - 64px);

	margin: 120px 32px 0 32px;

	font-size: min(40px, max(16vw, 16px));
	font-weight: 500;
	font-family: "OrticaAngular-Bold";
	color: #1c1917;
	text-align: left;
}

/* OLD */
.privacyPolicy-textSubtitle {
	margin: 64px 0 0 0;
	width: 100%;

	text-align: center;

	font-family: "TungstenNarrow-Semibold";
	font-weight: 600;
	font-size: 32px;
	color: #080d18;
	text-transform: uppercase;
	line-height: 48px;
}

.privacyPolicy-textBold {
	margin: 32px 0 0 28px;
	font-family: "Poppins";
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 24px;
	color: #080d18;
}

.privacyPolicy-text {
	width: calc(100% - 28px);

	margin: 16px 16px 16px 16px;
	font-family: "Poppins";
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	color: #080d18;
}

.privacyPolicy-update {
	width: calc(100% - 48px);
	margin: 64px 0 16px 28px;
	font-family: "Poppins";
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	color: #080d18;
	text-align: end;
}

.credits-default {
	width: 100%;

	margin: 0px 0px 16px 0px;
}

.credits-subtitle {
	margin: 0px 0px 16px 0px;
	font-weight: bold;
}
.cookies {
	z-index: 200000;
	position: absolute;
	width: 80%;

	bottom: 100px;

	margin: 16px 10% 0 10%;
	padding: 0 0 0 0;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;

	border-radius: 16px;

	background: #fdfaf7;

	box-shadow: 0px 2px 4px 0px #25191330;
	box-shadow: 0px 7px 7px 0px #25191329;
	box-shadow: 0px 17px 10px 0px #25191317;
	box-shadow: 0px 30px 12px 0px #25191308;
	box-shadow: 0px 46px 13px 0px #25191300;

	pointer-events: all;
}

.cookies-desc {
	width: calc(100% - 32px);
	margin: 16px 24px 8px 24px;

	font-family: "Geist";
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
	letter-spacing: 0.02em;
	text-align: left;

	line-height: 24px;
}

.cookies-desc a,
.cookies-desc a:visited,
.cookies-desc a:hover,
.cookies-desc a:active {
	color: #a1a1a1;
}

.cookies-buttons {
	width: calc(100% - 32px);

	margin: 0 16px 24px 16px;

	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.cookies-button {
	width: 100%;

	margin: 0.6em 8px 0 8px;

	border-radius: 32px;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	cursor: pointer;
	user-select: none;
}

.cookies-button-yes {
	background: #ede0d3;
	color: #2b1d16;
}

.cookies-button-no {
	background: #2B1D16;
	color: #FDFAF7;

}

.cookies-buttonPolicy {
	width: 100%;

	margin: 0.6em 0 0 0;

	border-radius: 32px;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	cursor: pointer;
	user-select: none;
}

.cookies-buttonPolicy-yes {
	margin: 0.6em 0.6em 0 0;

	background: #ede0d3;
	color: #2b1d16;
}

.cookies-buttonPolicy-no {
	margin: 0.6em 0 0 0.6em;

	background: #2b1d16;
	color: #fdfaf7;
}

.cookies-buttonText {
	height: 100%;

	right: 0%;
	margin: 14px 0 14px 0;

	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;

	font-family: "Geist";
	font-size: 16px;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: 0.02em;
	text-align: left;
}

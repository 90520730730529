.slider {
	position: absolute;
	z-index: 20;
	height: 100%;
	width: 102%;

	background-color: #FBF3EB;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

.slider-after {
	margin: auto;

	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;

	pointer-events: all;

	transition: all 2s;
}

.slider-before {
	z-index: 200;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;

	overflow-x: auto;

	pointer-events: all;

	transition: all 2s;
}

.slider-titleTop {
	position: absolute;
	z-index: 10;
	width: 100%;

	top: 10dvh;

	flex-direction: column;
	justify-content: flex-start;
	align-items: center;

	color: white;
	font-size: min(40px, max(16vw, 16px));
	font-weight: 500;
	font-family: "OrticaAngular-Bold";
	text-align: center;
}

.slider-buttonsBottom {
	position: absolute;
	z-index: 10;
	width: 100%;

	bottom: 10dvh;

	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;

	color: white;
	font-size: 16px;
	font-weight: 500;
	font-family: "Geist";
	letter-spacing: 2%;
	text-align: center;
}

.slider-buttonsBottomChange {
	margin: 0 0 28px 0;

	background-color: #fdfaf7;

	border-radius: 50px;

	pointer-events: all;
	cursor: pointer;
}

.slider-buttonsBottomChangeText {
	margin: 14px 26px 14px 14px;

	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	font-family: "Geist";
	font-size: 16px;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: 0.02em;
	text-align: left;

	color: #2B1D16;
}

.splashscreen-start-buttonTime {
	aspect-ratio: 1/1;
	height: 22px;

	margin: 0 12px 0 8px;

	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
}

.splashscreen-start-buttonTimeAntiHour {
	background-image: url("../../style/assets/icons/timeAntiHour.svg");
}

.splashscreen-start-buttonTimeHour {
	background-image: url("../../style/assets/icons/timeHour.svg");
}
.secretCode {
	position: absolute;
	z-index: 300;

	width: 100%;
	height: 100%;

	background-color: #ede0d3;

	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;

	pointer-events: all;
}

.secretCode-close {
	width: 48px;
	height: 48px;

	top: 0px;
	left: 50%;

	margin: 32px 0 0 0;

	border: 1px solid #2b1d16;
	border-radius: 50%;

	background-color: #2b1d16;
	background-position: center;
	background-size: 32px;
	background-repeat: no-repeat;
	background-image: url("../../style/assets/icons/cross.svg");
}

.secretCode-name {
	margin: 16px 0 32px 0;

	line-height: min(16px, max(8vw, 8px));
	font-size: min(16px, max(8vw, 8px));
	font-weight: 500;
	color: #2b1d16;
}

.secretCode-input {
	z-index: 10;
	width: 300px;
	height: 72px;

	margin: 68px 0 0 0;

	font-size: min(48px, max(16vw, 16px));
	font-weight: 500;
	font-family: "OrticaAngular-Bold";
	color: #1c1917;
	text-align: left;

	background-color: rgba(0, 0, 0, 0);
	border: 0px solid rgba(0, 0, 0, 0);

	outline: none;
}

.secretCode-input::placeholder {
	color: #ae968a;

	background-color: rgba(0, 0, 0, 0);
	border: 0px solid rgba(0, 0, 0, 0);
}

.secretCode-input:focus {
	background-color: rgba(0, 0, 0, 0);
	border: 0px solid rgba(0, 0, 0, 0);
	outline: none;
}

.secretCode-validate {
	height: 52px;
	/* width: 80%; */

	bottom: 0px;

	margin: 16px 0 32px 0;

	border-radius: 50px;

	background-color: #2B1D16;
	background-position: center;
	background-size: 32px;
	background-repeat: no-repeat;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.secretCode-validate2 {
	height: 52px;
	/* width: 80%; */

	bottom: 0px;

	margin: 16px 0 32px 0;

	border-radius: 50px;

	background-color: #2B1D16;
	background-position: center;
	background-size: 32px;
	background-repeat: no-repeat;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.secretCode-validate-buttonText {
	margin: 10px 32px 10px 32px;

	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	line-height: min(16px, max(8vw, 8px));
	font-size: min(16px, max(8vw, 8px));
	font-weight: 500;
	color: #FDFAF7;
}

.secretCode-valideBg {
	position: absolute;
	z-index: 8;
	height: 100%;
	width: 100%;

	background-color: #8aaf83;
}

.secretCode-failureBg {
	position: absolute;
	z-index: 8;
	height: 100%;
	width: 100%;

	background-color: #EFBE03;
}

/* Congrats */
.secretCode-congratsContainer {
	position: absolute;
	z-index: 12;
	height: 100%;
	width: 100%;
}

.secretCode-congratsWhite {
	position: absolute;
	height: 100%;
	width: 100%;

	background: url(../../style/assets/secretCode/background.jpg) 0% 0% /
		26.333332061767578px 26.333332061767578px repeat;
}

.secretCode-congrats {
	position: absolute;
	height: 100%;
	width: 100%;

	background-color: #fbf3eb;
	/*background: url(../../style/assets/paperDarken.jpg);*/
	background-position: center;
	background-size: 200% 200%;
	background-repeat: no-repeat;
	background-blend-mode: luminosity, multiply, normal;

	text-align: left;

	overflow-y: auto;
}

.secretCode-congrats-imgContainer {
	margin: 16px auto 16px auto;

	display: flex;
	aspect-ratio: 1/1;
	width: 70%;

	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 8px;
}

.secretCode-congrats-img {
	aspect-ratio: 1/1;
	width: 80%;

	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	background-image: url("../../style/assets/pois/thermalBaths/objectIcon.png");

	mix-blend-mode: multiply;
}

.secretCode-congrats-bravo {
	width: 80%;

	font-size: min(48px, max(16vw, 16px));
	font-weight: 500;
	font-family: "OrticaAngular-Bold";
	color: #1c1917;
	text-align: center;
}

.secretCode-congrats-found {
	margin: 8px 32px 0 32px;

	width: calc(100% - 64px);

	color: #423129;
	font-family: "Geist";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	letter-spacing: 0.28px;
	text-align: center;
}

.secretCode-congrats-objectName {
	width: calc(100% - 64px);

	margin: 64px 32px 0 32px;

	display: "flex";
	flex-direction: column;
	justify-content: center;
	align-items: center;

	line-height: min(32px, max(12vw, 12px));
	font-size: min(32px, max(12vw, 12px));
	font-weight: 500;
	font-family: "OrticaAngular-Bold";
	color: #1c1917;
	text-align: center;
}

.secretCode-congrats-desc {
	width: calc(100% - 64px);

	margin: 16px 32px 0 32px;

	color: #423129;
	font-family: "Geist";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	letter-spacing: 0.28px;
}

.secretCode-congrats-slide {
	position: absolute;
	width: calc(100% - 64px);

	margin: 16px 32px 0 32px;
	bottom: 120px;

	line-height: min(20px, max(10vw, 10px));
	font-size: min(14px, max(8vw, 8px));
	font-weight: 400;
	color: #2b1d16;

	text-align: center;
}

.secretCode-congrats-continue {
	height: 52px;
	width: 80%;

	bottom: 0px;

	margin: 48px 0 32px 0;

	border-radius: 50px;
	border: 1px solid #2b1d16;

	background-color: #8aaf83;
	background-position: center;
	background-size: 32px;
	background-repeat: no-repeat;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	box-shadow: 0px 1px 0px 0px #463025;
}

.secretCode-congrats-blank {
	position: relative;
	width: 100%;
	height: 120px;
}

.secretCode-congrats-bookContainer {
	position: relative;
	aspect-ratio: 21/29;
	width: 100%;

	margin: 48px auto 0 auto;

	overflow: hidden;
}

.secretCode-congrats-book {
	position: relative;
	aspect-ratio: 29/21;
	width: 180%;

	left: 16px;

	transform: rotate(1deg);

	overflow: hidden;
}

.secretCode-congrats-bookLeft {
	position: absolute;
	height: 98%;
	width: 49%;

	top: 1%;
	left: 1%;

	background: linear-gradient(
		90deg,
		#f7ede4 73.64%,
		#fbf3eb 89%,
		#f1e5da 98.5%
	);
	mix-blend-mode: multiply;

	box-shadow: 0px 1px 1px 0px rgba(70, 48, 37, 0.2),
		0px 0px 1px 0px rgba(70, 48, 37, 0.34),
		0px 0px 2px 0px rgba(70, 48, 37, 0.16);

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.secretCode-congrats-bookRight {
	position: absolute;
	height: 98%;
	width: 49%;

	top: 1%;
	left: 50%;

	border-radius: 0px;
	background: linear-gradient(
		270deg,
		#f7ede4 73.64%,
		#fbf3eb 89%,
		#f1e5da 98.5%
	);
	mix-blend-mode: multiply;

	box-shadow: 0px 1px 1px 0px rgba(70, 48, 37, 0.2),
		0px 0px 1px 0px rgba(70, 48, 37, 0.34),
		0px 0px 2px 0px rgba(70, 48, 37, 0.16);
}

.continueButton {
	width: 50%;
	height: 48px;

	margin: 24px auto 32px auto;

	background-color: #ede0d3;
	border-radius: 28px;

	pointer-events: all;

	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	font-family: "Geist";
	font-size: 16px;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: 0.02em;
	text-align: left;

	color: #2b1d16;
}

.secretCode-buttonArrow {
	aspect-ratio: 1/1;
	height: 22px;

	margin: 0 0 0 12px;

	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
	background-image: url("../../style/assets/icons/arrow-right.svg");
}

#notelem {
	margin: 32px 0 32px 0;

	line-height: min(16px, max(8vw, 8px));
	font-size: min(16px, max(8vw, 8px));
	font-weight: 500;
	color: #2b1d16;
}
.App {
	position: absolute;
	height: 100%;
	width: 100%;

	text-align: center;

	display: flex;
	flex-flow: column;

	background-color: #FBF3EB;

	overflow: hidden;
}

.app-container {
	position: absolute;
	z-index: 2;
	text-align: center;

	display: flex;
	flex-flow: column;

	/* background-color: rgba(255, 0, 0, 0.1); */
	box-sizing: content-box;
}

.app-content {
	position: relative;
	flex: 1 1 auto;

	width: 100%;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.dev-ui-top {
	flex: 0 1 auto;

	aspect-ratio: 390/44;
	width: 100%;

	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
}

.dev-ui-bottom {
	flex: 0 1 auto;

	aspect-ratio: 390/93;
	width: 100%;

	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
}

.app-container {
	pointer-events: none;
}
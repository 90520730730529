.glossary {
	position: absolute;

	width: 100%;
	height: 100%;

	background-color: #fbf3eb;
}

.glossary-ticket {
	position: absolute;
	aspect-ratio: 50/142;
	width: 48px;

	right: 0;
	top: 0;

	transform: translate(-80%, -20%);

	background-image: url("../../style/assets/ticket.png");
	background-repeat: no-repeat;
	background-size: contain;
}

.glossary-header {
	position: absolute;
	width: 100%;

	margin: 0 0 0 0;

	align-self: flex-start;
}

.glossary-title {
	width: calc(100% - 32px);

	margin: 64px 0 0 32px;

	font-size: min(40px, max(16vw, 16px));
	font-weight: 500;
	font-family: "OrticaAngular-Bold";
	color: #1c1917;

	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
}

.glossary-subtitle {
	width: calc(95% - 64px);

	position: relative;

	margin: 8px 32px 32px 32px;

	text-align: left;

	line-height: min(20px, max(10vw, 10px));
	font-size: min(14px, max(8vw, 8px));
	font-weight: 400;
	letter-spacing: 0.02rem;
}

.glossary-content {
	position: absolute;
	width: 100%;
	height: 100%;

	overflow: overlay;

	pointer-events: all;
}

.glossary-body {
	position: absolute;
	width: 100%;

	margin: 360px auto 0 auto;

	pointer-events: all;
}

.glossaryCard {
	position: absolute;
	width: 100%;
	height: 100%;
	max-height: 0px;

	padding: 24px 0 40px 0;

	background: #fdfaf7;

	border-radius: 16px 16px 0 0;

	box-sizing: border-box;

	box-shadow: 0px -2px 4px 0px #2b1c1627;

	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;

	transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.glossaryCard-title {
	margin: 0 24px 0 24px;

	font-family: "OrticaLinear-Regular";
	font-size: 18px;
	font-weight: 700;
	line-height: 20px;
	text-align: center;
	color: #2b1d16;

	pointer-events: none;
}

.glossaryCard-content {
	position: absolute;
	width: 100%;

	top: 52px;

	background: #fdfaf7;

	transition: opacity 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.glossaryCard-subtitle {
	margin : 16px 24px 0 24px;

	font-family: "OrticaLinear-Regular";
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
	letter-spacing: 0.02em;
	text-align: left;

	pointer-events: none;
}

.glossaryCard-blank {
	position: absolute;
	width: 100%;

	padding: 24px 0 40px 0;

	background: #fdfaf7;

	box-sizing: border-box;

	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
}

@keyframes locked {
	0% {
		max-height: 0px;
	}
	25% {
		max-height: 120px;
	}
	35% {
		max-height: 100px;
	}
	45% {
		max-height: 120px;
	}
	70% {
		max-height: 0px;
	}
	100% {
		max-height: 0px;
	}
}

.effectLocked {
	animation: locked 1.5s linear 0s running;
}
html,
body,
#root {
	width: 100%;
	height: 100%;

	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: #fbf3eb;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

@media screen and (min-aspect-ratio: 2/3) {
	.app-container {
		/* background-color: green; */

		aspect-ratio: 9/12;
		height: 100%;
		left: 50%;
		transform: translateX(-50%);
	}
}

@media screen and (min-aspect-ratio: 3/7) and (max-aspect-ratio: 2/3) {
	.app-container {
		/* background-color: blue; */

		height: 100%;
		width: 100%;
	}
}

@media screen and (max-aspect-ratio: 3/7) {
	.app-container {
		/* background-color: red; */

		aspect-ratio: 3/7;
		width: 100%;
		top: 50%;
		transform: translateY(-50%);
	}
}

@media screen and (max-aspect-ratio: 1/1) {
	.map {
		background-size: cover;
	}
}

@media screen and (min-aspect-ratio: 1/1) {
	.map {
		background-size: cover;
	}
}

/* Fonts */
@font-face {
	font-family: "OrticaLinear-Regular";
	src: url("style/fonts/OrticaLinear-Regular.woff2") format("woff");
}

@font-face {
	font-family: "OrticaAngular-Bold";
	src: url("style/fonts/OrticaAngular-Bold.woff2") format("woff");
	font-weight: bold;
}

@font-face {
	font-family: "OrticaLinear-Bold";
	src: url("style/fonts/OrticaLinear-Bold.woff2") format("woff");
	font-weight: bold;
}

@font-face {
	font-family: "OrticaLinear-Light";
	src: url("style/fonts/OrticaLinear-Light.woff2") format("woff");
}

@font-face {
	font-family: "Geist";
	src: url("style/fonts/GeistVF.woff2") format("woff2");
}

.link {
	color: #0164f8;
	text-decoration: none;
}

.glossaryPopup {
	position: absolute;
	z-index: 20000;
	width: calc(100% - 32px);

	bottom: 16px;
	left: 16px;

	display: flex;
	flex-direction: column;
	align-items: left;
	gap: 16px;

	border-radius: 16px;
	background: #fdfaf7;

	box-shadow: 0px 46px 13px 0px rgba(37, 25, 19, 0),
		0px 30px 12px 0px rgba(37, 25, 19, 0.03),
		0px 17px 10px 0px rgba(37, 25, 19, 0.09),
		0px 7px 7px 0px rgba(37, 25, 19, 0.16),
		0px 2px 4px 0px rgba(37, 25, 19, 0.19);

	opacity: 0;

	transform: translateY(150%);
	transition: transform 0.5s ease-in-out;
}

.glossaryPopupTitle {
	margin: 24px 24px 0 24px;

	color: #2b1d16;
	font-family: "Ortica Linear";
	font-size: 18px;
	font-style: normal;
	font-weight: 700;
	line-height: 20px;

	text-align: left;
}

.glossaryPopupDesc {
	margin: 4px 24px 0 24px;

	color: #423129;

	font-family: "Geist";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	letter-spacing: 0.28px;

	text-align: left;
}

.glossaryPopupUnlock {
	margin: 4px 24px 0 24px;

	color: #765c4e;

	font-family: "Geist";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	letter-spacing: 0.28px;

	text-align: left;
}

.glossaryPopupButton {
	width: calc(100% - 48px);
	height: 48px;

	margin: 4px auto 24px auto;

	background-color: #ede0d3;
	border-radius: 28px;

	pointer-events: all;

	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	font-family: "Geist";
	font-size: 16px;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: 0.02em;
	text-align: left;

	color: #2b1d16;
}

.glossaryPopupButtonText {
	margin: 14px;

	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	font-family: "Geist";
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	letter-spacing: 0.32px;
	color: #2b1d16;
}

.glossaryPopupButtonArrow {
	aspect-ratio: 1/1;
	height: 22px;

	margin: 0 0 0 12px;

	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
	background-image: url("./style/assets/icons/arrow-right.svg");
}

.glossaryPopupBackground {
	position: absolute;
	pointer-events: all;
	height: 100%;
	width: 100%;

	z-index: 19999;
}


/* Share */
.sharePopup {
	position: absolute;
	z-index: 50000;
	width: calc(100% - 32px);

	bottom: 16px;
	left: 16px;

	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 16px;

	border-radius: 16px;
	background: #fdfaf7;

	box-shadow: 0px 46px 13px 0px rgba(37, 25, 19, 0),
		0px 30px 12px 0px rgba(37, 25, 19, 0.03),
		0px 17px 10px 0px rgba(37, 25, 19, 0.09),
		0px 7px 7px 0px rgba(37, 25, 19, 0.16),
		0px 2px 4px 0px rgba(37, 25, 19, 0.19);

	opacity: 0;

	transform: translateY(150%);
	transition: transform 0.5s ease-in-out;
}

.sharePopupTitle {
	width: 70%;

	margin: 24px 24px 0 24px;

	color: #2b1d16;
	font-family: "Geist";
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 20px;

	text-align: center;
}

.sharePopupDesc {
	position: relative;
	height: 100%;
	width: 100%;

	margin: 4px 24px 0 24px;

	color: #423129;

	font-family: "Geist";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	letter-spacing: 0.28px;

	text-align: left;

	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.sharePopupSocial {
	position: relative;
	height: 100%;
	width: 25%;

	margin-bottom: 24px;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.sharePopupSocial-img {
	aspect-ratio: 1/1;
	height: 48px;

	margin: 8px;

	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
}

.sharePopupUnlock {
	margin: 4px 24px 0 24px;

	color: #765c4e;

	font-family: "Geist";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	letter-spacing: 0.28px;

	text-align: left;
}

.sharePopupButton {
	width: calc(100% - 48px);
	height: 48px;

	margin: 4px auto 24px auto;

	background-color: #ede0d3;
	border-radius: 28px;

	pointer-events: all;

	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	font-family: "Geist";
	font-size: 16px;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: 0.02em;
	text-align: left;

	color: #2b1d16;
}

.sharePopupButtonText {
	margin: 14px;

	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	font-family: "Geist";
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	letter-spacing: 0.32px;
	color: #2b1d16;
}

.sharePopupButtonArrow {
	aspect-ratio: 1/1;
	height: 22px;

	margin: 0 0 0 12px;

	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
	background-image: url("./style/assets/icons/arrow-right.svg");
}

.sharePopupBackground {
	position: absolute;
	pointer-events: all;
	height: 100%;
	width: 100%;

	z-index: 31000;
}

.endExpReload-shareButton {
	position: absolute;
	z-index: 30000;
	width: 80%;
	height: 48px;

	bottom: 98px;
	left: 50%;

	transform: translateX(-50%);

	margin: 24px auto 8px auto;

	background-color: #2B1D16;
	border-radius: 28px;

	pointer-events: all;

	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	font-family: "Geist";
	font-size: 16px;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: 0.02em;
	text-align: left;

	color: #FDFAF7;
}
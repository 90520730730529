.poi {
	position: absolute;
	z-index: 600;

	bottom: 0%;
	left: 0%;

	width: 100%;
	height: 100%;

	transition: all 2s ease-out;

	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;

	background-color: lightgray;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;

	clip-path: circle(0px at center 96%);

	pointer-events: all;
}

.poi2 {
	position: absolute;
	z-index: 600;

	bottom: 0%;
	left: 0%;

	width: 100%;
	height: 100%;

	transition: all 2s ease-out;

	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;

	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	background-image: url("../../style/assets/splashscreen/splashscreen_background.jpg");

	clip-path: circle(60px at center 96%);

	pointer-events: all;
}

.poi-uiHeader {
	position: absolute;
	z-index: 16;
	width: 80%;
	height: 48px;

	top: 0px;

	margin: 32px 0 0 0;

	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.poi-filter {
	height: 48px;

	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	border-radius: 50px;

	pointer-events: all;
	cursor: pointer;

	background-color: #2b1d16;
}

.poi-filterText {
	margin: 14px 26px 14px 14px;

	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	font-family: "Geist";
	font-size: 16px;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: 0.02em;
	text-align: left;

	color: #a5c19f;
}

.poi-filterTime {
	aspect-ratio: 1/1;
	height: 22px;

	margin: 0 12px 0 8px;

	background-image: url("../../style/assets/icons/timeAntiHour.svg");
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;

	filter: invert();
}

.poi-close {
	width: 48px;
	height: 48px;

	margin: 0 0 0 0;

	border: 1px solid #2b1d16;
	border-radius: 50%;

	background-color: #2b1d16;
	background-position: center;
	background-size: 32px;
	background-repeat: no-repeat;
	background-image: url("../../style/assets/icons/cross.svg");
}

.poi-color {
	position: absolute;
	z-index: 15;
	height: 100%;
	width: 100%;

	background-color: #2b1d16;

	transition: all 1s ease-out;

	pointer-events: none;
}

.poi-colorInside {
	position: absolute;
	height: 64px;
	width: 64px;

	left: 50%;
	bottom: 26px;

	transform: translateX(-50%);

	color: white;
}

.poi-content {
	position: absolute;
	width: 100%;
	height: 100%;

	overflow: overlay;

	pointer-events: all;
}

.poi-header {
	position: absolute;
	width: 100%;

	bottom: 18dvh;

	align-self: flex-end;
}

.poi-numberContent {
	position: absolute;

	height: 15dvh;

	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
}

.poi-number {
	vertical-align: top;
	line-height: 240px;
	font-size: 240px;
	font-weight: 500;
	font-family: "OrticaAngular-Bold";
	color: #cfdacdd3;
	text-align: center;
	letter-spacing: -0.05em;

	mix-blend-mode: overlay;
}

.poi-numberOn {
	position: absolute;
	aspect-ratio: 1/1;
	width: 10%;

	top: 0%;
	right: 0%;

	vertical-align: top;
	line-height: 100px;
	font-size: 40px;
	font-weight: 500;
	font-family: "OrticaAngular-Bold";
	color: #a5c19f;
	text-align: center;
	letter-spacing: 0.02rem;

	mix-blend-mode: overlay;

	transform: translateX(110%);
}

.poi-text {
	margin: 140px 26px 0 26px;

	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	color: #fdfaf7c0;
}

.poi-title {
	width: 100%;

	text-align: start;
	line-height: min(50px, max(20vw, 8px));
	font-size: min(50px, max(20vw, 8px));
	font-weight: 700;
}

.poi-subtitle {
	margin: 16px 0 0 0;

	line-height: min(20px, max(16vw, 10px));
	font-size: min(20px, max(16vw, 8px));
	font-weight: 400;
	text-align: left;
}

.poi-body {
	position: absolute;
	width: 100%;

	margin: 85dvh auto 0 auto;

	background: linear-gradient(180deg, #2b1d1600 0%, #ede0d3 100%);
}

.poi-section-bloc {
	position: relative;
	width: 90%;
	min-height: 10px;

	margin: 12px auto 0 auto;

	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;

	border-radius: 4px;
	background: url(../../style/assets/pois/paper.png) repeat, #fdfaf7;
	background-blend-mode: normal;

	/* Overlay shadow/lg */
	box-shadow: 0px 46px 13px 0px rgba(37, 25, 19, 0),
		0px 30px 12px 0px rgba(37, 25, 19, 0.03),
		0px 17px 10px 0px rgba(37, 25, 19, 0.09),
		0px 7px 7px 0px rgba(37, 25, 19, 0.16),
		0px 2px 4px 0px rgba(37, 25, 19, 0.19);
}

.poi-section-title {
	color: #765c4e;
	font-family: "OrticaLinear-Regular";
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 16px;
	letter-spacing: 0.96px;
	text-transform: uppercase;
	text-align: left;

	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

	width: calc(100% - 48px);
}

.poi-section-titleLine {
	height: 1px;
	width: 100%;

	border-bottom: 1px solid #b19f8e;
}

.poi-section-bloc-title {
	position: relative;

	margin: 0px 24px 0 24px;

	text-align: left;

	line-height: min(20px, max(10vw, 10px));
	font-size: min(16px, max(8vw, 8px));
	font-weight: 500;
	letter-spacing: 0.02rem;
}

.poi-section-bloc-subtitle {
	position: relative;

	margin: 16px 24px 24px 24px;

	text-align: left;

	line-height: min(20px, max(10vw, 10px));
	font-size: min(16px, max(8vw, 8px));
	font-weight: 400;
	letter-spacing: 0.02rem;
}

.poi-section-encart {
	position: relative;
	width: 90%;
	min-height: 10px;

	margin: 12px auto 0 auto;

	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;

	border-radius: 4px;
	background: #765c4e;
	background-blend-mode: normal;

	/* Overlay shadow/lg */
	box-shadow: 0px 46px 13px 0px rgba(37, 25, 19, 0),
		0px 30px 12px 0px rgba(37, 25, 19, 0.03),
		0px 17px 10px 0px rgba(37, 25, 19, 0.09),
		0px 7px 7px 0px rgba(37, 25, 19, 0.16),
		0px 2px 4px 0px rgba(37, 25, 19, 0.19);

	color: #fdfaf7;
}

.poi-section-encart-title {
	position: relative;

	margin: 24px 24px 0 24px;

	text-align: left;

	line-height: min(20px, max(10vw, 10px));
	font-size: min(16px, max(8vw, 8px));
	font-weight: 500;
	letter-spacing: 0.02rem;
}

.poi-section-encart-subtitle {
	position: relative;

	margin: 16px 24px 24px 24px;

	text-align: left;

	line-height: min(20px, max(10vw, 10px));
	font-size: min(16px, max(8vw, 8px));
	font-weight: 400;
	letter-spacing: 0.02rem;
}

.poi-section-img {
	position: relative;
	width: 90%;
	min-height: 10px;

	margin: 16px auto 16px auto;

	background: url(../../style/assets/pois/paper.png) lightgray 20% / cover
		no-repeat;

	/* background-blend-mode: darken; */
}

.poi-start-button {
	width: 90%;

	margin: 40px auto 80px auto;

	background-color: #2b1d16;

	border-radius: 50px;

	pointer-events: all;

	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
}

.poi-start-buttonText {
	margin: 14px;

	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	font-family: "Geist";
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	letter-spacing: 0.32px;
	color: #fdfaf7;
}

.poi-start-buttonArrow {
	aspect-ratio: 1/1;
	height: 22px;

	margin: 0 0 0 12px;

	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
	background-image: url("../../style/assets/icons/arrow-right-white.svg");
}
.timeline {
	position: absolute;

	width: 100%;
	height: 100%;

	background-color: #fbf3eb;
}

.timeline-header {
	position: absolute;
	width: 100%;

	margin: 0 0 0 0;

	align-self: flex-start;
}

.timeline-title {
	width: calc(100% - 32px);

	margin: 64px 0 0 32px;

	font-size: min(40px, max(16vw, 16px));
	font-weight: 500;
	font-family: "OrticaAngular-Bold";
	color: #1c1917;
	text-align: left;

	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
}

.timeline-subtitle {
	width: calc(95% - 64px);

	position: relative;

	margin: 8px 32px 32px 32px;

	text-align: left;

	line-height: min(20px, max(10vw, 10px));
	font-size: min(14px, max(8vw, 8px));
	font-weight: 400;
	letter-spacing: 0.02rem;
}

.timeline-content {
	position: absolute;
	width: 100%;
	height: 100%;

	overflow: overlay;

	pointer-events: all;

	scroll-behavior: smooth;
}

.timeline-years {
	position: absolute;
	z-index: 803;
	height: 100%;
	width: 24px;

	margin: 16px auto 0 auto;
}

.timeline-millenniums {
	position: absolute;
	z-index: 802;
	width: 24px;
	height: 100%;

	background-repeat: repeat-x;
	background-image: url("../../style/assets/timeline/graduation_1000year.svg");
	background-size: 100%;

	transform: translateY(-1px);
}

.timeline-centuries {
	position: absolute;
	z-index: 801;
	width: 24px;
	height: 100%;

	background-repeat: repeat;
	background-image: url("../../style/assets/timeline/graduation_100year.svg");
}

.timeline-decades {
	position: absolute;
	z-index: 1;
	width: 8px;
	height: 100%;

	background-repeat: repeat;
	background-image: url("../../style/assets/timeline/graduation_10year.svg");
}

.timeline-yearName {
	position: absolute;
	z-index: 600;
	width: 200px;

	margin-top: 8px;

	top: 8px;
	left: 0px;

	font-size: 16px;
	font-weight: 600;
	line-height: 24px;
	letter-spacing: 0.02em;
	text-align: center;
	color: #765c4e;

	transform-origin: top left;
	transform: rotate(90deg) translateY(-100%) translateY(-8px);

	overflow: visible;

	text-align: left;
}

.timeline-body {
	position: absolute;
	width: 100%;

	margin: 340px auto 0 auto;

	pointer-events: all;

	background: linear-gradient(180deg, #fbf3eb10 0%, #fbf3eb 16px, #fbf3eb 100%);
}

.timeline-period {
	position: absolute;
	width: 100%;

	margin-left: 8px;

	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
}

.timeline-period-top {
	position: absolute;
	width: 100%;
	height: 20px;

	top: 0px;
	left: 0px;

	transform: translateY(-10px);

	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
}

.timeline-period-top-delimiter1 {
	height: 1px;
	width: 24px;
}

.timeline-period-top-text {
	width: calc(100% - 32px);
	margin: 0 0 0 8px;

	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.timeline-period-top-textContent {
	margin: 0 8px 0 0;

	white-space: nowrap;

	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: baseline;
}

.timeline-period-top-textContent-year {
	color: #2b1d16;

	font-size: 14px;
	font-weight: 600;
}

.timeline-period-top-textContent-desc {
	margin: 0 0 0 6px;
	font-size: 14px;
}

.timeline-period-top-delimiter2 {
	height: 1px;
	width: 100%;
}

.timeline-cardName {
	position: sticky;
	width: 100%;

	margin: 16px 16px 16px 32px;

	top: 24px;

	text-align: left;
	font-size: 14px;
}

.timeline-cardName-periodColor {
	position: relative;
	width: 40px;
	height: 100%;
}

.timeline-cardName-periodColorName {
	position: sticky;
	width: 200px;

	margin-top: 12px;

	top: 12px;
	left: 0px;

	font-size: 16px;
	font-weight: 600;
	line-height: 24px;
	letter-spacing: 0.02em;
	text-align: center;
	color: #fdfaf7;

	transform-origin: top left;
	transform: rotate(90deg) translateY(-100%) translateY(-10px);

	overflow: visible;

	text-align: left;

	text-wrap: nowrap;
}

.timeline-scrollArrowsContainer {
	width: 100%;

	top: 80px;
}

.timeline-scrollArrows {
	width: 100%;

	margin: 16px 16px 16px 0;

	text-align: left;
	font-size: 14px;

	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
}

.timeline-scrollArrow {
	margin: 0 8px 0 0;

	background-color: #EDE0D3;
	border-radius: 16px;
}

.timeline-scrollArrowTop {
	aspect-ratio: 1/1;
	height: 18px;

	margin: 6px 14px;

	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
	background-image: url("../../style/assets/icons/arrow-right.svg");

	transform: rotate(270deg);
}

.timeline-scrollArrowBottom {
	aspect-ratio: 1/1;
	height: 18px;

	margin: 6px 14px;

	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
	background-image: url("../../style/assets/icons/arrow-right.svg");

	transform: rotate(90deg);
}

.timeline-period-bottom {
	position: absolute;
	width: 100%;
	height: 20px;

	bottom: 0px;
	left: 0px;

	transform: translateY(10px);

	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
}

/* Subtimeline */
.subtimeline-period {
	position: absolute;
	z-index: 200;
	width: calc(100% - 40px);

	top: 16px;

	transform: translateY(-16px);

	margin-left: 8px;

	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;

	background: linear-gradient(180deg,
			#fbf3eb10 0%,
			#fbf3eb 8px,
			#fbf3eb calc(100% - 8px),
			#fbf3eb10 100%);
}

.subtimeline-period-top {
	position: absolute;
	width: 100%;
	height: 20px;

	top: 0px;
	left: 0px;

	transform: translateY(6px);

	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
}

.subtimeline-period-top-delimiter1 {
	display: flex;

	height: 1px;
	width: 24px;
}

.subtimeline-period-top-text {
	width: calc(100% - 32px);
	margin: 0 0 0 8px;

	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.subtimeline-period-top-textContent {
	margin: 0 8px 0 0;

	max-width: calc(100dvw - 80px);

	white-space: nowrap;

	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: baseline;
}

.subtimeline-period-top-textContent-year {
	color: #2b1d16;

	font-size: 14px;
	font-weight: 600;
}

.subtimeline-period-top-textContent-desc {
	position: absolute;

	margin: 0 0 0 6px;
	padding: 0 6px 0 2px;

	text-align: left;
	white-space: break-spaces;
    white-space-collapse: break-spaces;
	text-wrap-mode: wrap;


	background-color: #fbf3ebFB;
	font-size: 14px;
}

.subtimeline-period-top-delimiter2 {
	height: 1px;
	width: 100%;
}

.subtimeline-cardName {
	position: sticky;

	margin: 46px 16px 48px 32px;

	top: 36px;

	transform: translateY(16px);

	text-align: left;
	font-size: 14px;
}

.subtimeline-cardName-periodColor {
	position: relative;
	width: 40px;
	height: calc(100% - 32px);

	margin: 16px 0 0 0;

	transform: translateY(16px);

	white-space: nowrap;
}

.subtimeline-cardName-periodColorName {
	position: sticky;
	width: 200px;

	margin-top: 12px;

	top: 28px;
	left: 0px;

	font-size: 16px;
	font-weight: 600;
	line-height: 24px;
	letter-spacing: 0.02em;
	text-align: center;
	color: #fdfaf7;

	transform-origin: top left;
	transform: rotate(90deg) translateY(-100%) translateY(-10px);

	overflow: visible;

	text-align: left;
}

.subtimeline-period-bottom {
	position: absolute;
	width: 100%;
	height: 20px;

	bottom: 0px;
	left: 0px;

	transform: translateY(10px);

	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
}

/* BottomPage */
.timeline-bottomPage {
	position: absolute;
	z-index: 300;
	height: 160px;
	width: 100%;

	bottom: 80px;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.timeline-bottomPageText {
	line-height: min(20px, max(10vw, 10px));
	font-size: min(14px, max(8vw, 8px));
	font-weight: 400;
	letter-spacing: 0.02rem;
}

.timeline-bottomPageButton {
	background-color: #ede0d3;

	margin: 16px;

	border-radius: 50px;

	pointer-events: all;
}

.timeline-bottomPageButtonText {
	margin: 14px 28px 14px 28px;

	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	line-height: min(16px, max(8vw, 8px));
	font-size: min(16px, max(8vw, 8px));
	font-weight: 500;
	color: #1c1917;
}

.timeline-bottomPageButtonArrow {
	aspect-ratio: 1/1;
	height: 22px;

	margin: 0 0 0 12px;

	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
	background-image: url("../../style/assets/icons/arrow-right.svg");

	transform: rotate(270deg);
}

.timeline-modalContainer {
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: 1000;
	top: 0%;
	left: 0%;

	backdrop-filter: blur(10px);
}

.timeline-modal {
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: 1000;
	top: 0%;
	left: 0%;

	backdrop-filter: blur(10px);
}
.splashscreen {
	position: absolute;

	width: 100%;
	height: 100%;

	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	background-image: url("../../style/assets/splashscreen/splashscreen_background.jpg");

	pointer-events: all;
}

/* Content */
.splashscreen-content {
	position: absolute;

	top: 40%;
	left: 50%;
	transform: translate(-50%, -80%);
}

.splashscreen-title {
	margin: 0 0 0 0;

	font-family: "OrticaAngular-Bold";
	line-height: min(52px, max(14vw, 14px));
	color: #1c1917;
}

.splashscreen-title-line1 {
	font-size: min(64px, max(16vw, 12px));
}

.splashscreen-title-line2 {
	font-size: min(64px, max(15vw, 12px));
}

.splashscreen-subtitle {
	font-family: "OrticaLinear-Light";
	line-height: min(20px, max(8vw, 8px));
	font-size: min(20px, max(8vw, 8px));
	color: #1c1917;
}

/* Bottom Menu */
.splashscreen-start {
	position: absolute;
	width: 100%;

	bottom: 0%;

	transform: translateY(-50%);

	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
}

.splashscreen-start-button {
	width: 80%;

	background-color: #fdfaf7;

	border-radius: 50px;

	pointer-events: all;
	cursor: pointer;
}

.splashscreen-start-buttonText {
	margin: 14px;

	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	font-family: "Geist";
	font-size: 16px;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: 0.02em;
	text-align: left;

	color: #2B1D16;
}

.splashscreen-start-buttonArrow {
	aspect-ratio: 1/1;
	height: 22px;

	margin: 0 0 0 12px;

	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
	background-image: url("../../style/assets/icons/arrow-right.svg");
}

.splashscreen-start-langSelect {
	width: 80%;

	margin: 14px 0 0 0;

	border-radius: 50px;

	cursor: pointer;
	pointer-events: all;
}

.splashscreen-start-langSelectFlag {
	aspect-ratio: 1/1;
	height: 22px;

	margin: 0 8px 0 0;

	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
}

.splashscreen-start-langSelectText {
	margin: 10px;

	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	font-family: "Geist";
	font-size: 16px;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: 0.02em;
	text-align: left;

	color: #fdfaf7;
}

.splashscreen-start-langSelectArrow {
	aspect-ratio: 1/1;
	height: 22px;

	margin: 0 0 0 12px;

	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
	background-image: url("../../style/assets/icons/chevron-down-sharp.svg");
}

.splashscreenPopup {
	position: absolute;
	z-index: 22000;
	width: 80%;
	bottom: -24px;

	transform: translateY(200%);

	margin: 10px;
	padding: 24px 0 24px 0;

	display: flex;
	flex-direction: column;
	align-items: left;
	gap: 16px;

	border-radius: 16px;
	background: #fdfaf7;

	box-shadow: 0px 46px 13px 0px rgba(37, 25, 19, 0),
		0px 30px 12px 0px rgba(37, 25, 19, 0.03),
		0px 17px 10px 0px rgba(37, 25, 19, 0.09),
		0px 7px 7px 0px rgba(37, 25, 19, 0.16),
		0px 2px 4px 0px rgba(37, 25, 19, 0.19);
	
	transition: all 0.3s linear;
}

.splashscreenPopupButton {
	width: calc(100% - 48px);
	height: 48px;

	margin: 2px auto 2px auto;

	background-color: #ede0d3;
	border-radius: 28px;

	pointer-events: all;

	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	font-family: "Geist";
	font-size: 16px;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: 0.02em;
	text-align: left;

	color: #2b1d16;
}

.splashscreenPopupButtonSelected {
	background-color: #2B1D16;
}

.splashscreenPopupButtonText {
	margin: 14px;

	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	font-family: "Geist";
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	letter-spacing: 0.32px;
	color: #2b1d16;
}

.splashscreenPopupButtonTextSelected {
	color: #FDFAF7;
}

.splashscreenPopupButtonArrow {
	aspect-ratio: 1/1;
	height: 22px;

	margin: 0 0 0 12px;

	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
	background-image: url("../../style/assets/icons/arrow-right.svg");
}
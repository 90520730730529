.map {
	position: absolute;
	z-index: 400;

	height: 102%;
	width: 102%;

	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.map::-webkit-scrollbar {
	display: none;
}

.map-imgContainer {
	position: absolute;
	height: 200%;
	width: 200%;

	top: 50%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);

	/*overflow: scroll;*/
	pointer-events: all;
}

.map-imgContent {
	position: absolute;
	aspect-ratio: 1636/1832;
	height: 110%; /* Change this value to zoom */

	top: calc(110% / 2);

	background-position: center;
	background-size: 100% 100%;
	background-repeat: no-repeat;

	transform: translateY(-50%);

	/* transition: all 4s ease-in-out; */
}

/* Pois Point */
.map-pois-poi {
	position: absolute;

	height: 4px;
	width: 4px;

	transform: translateX(-50%) translateY(-50%);

	transition: all 4s ease-in-out;
}

.map-pois-poi-dot {
	position: absolute;

	height: 100%;
	width: 100%;

	border-radius: 12.092px;
	border: 1.099px solid #ede0d3;
	background: #2b1d16;

	transition: all 4s ease-in-out;
}

@keyframes pulse {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
	}

	70% {
		transform: scale(1.3);
		box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}

.map-pois-poi-selected {
	animation: pulse 2s infinite;
}

.map-pois-poiLabel {
	position: absolute;

	top: 50%;

	transform: translateY(-50%);

	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;

	filter: drop-shadow(0px 1px 1px rgba(70, 48, 37, 0.2)) drop-shadow(0px 0px 1px rgba(70, 48, 37, 0.34))
		drop-shadow(0px 0px 2px rgba(70, 48, 37, 0.16));

	overflow: visible;
}

.map-pois-poiLabelText {
	display: flex;
	padding: 4px 4px 4px 4px;
	justify-content: center;
	align-items: center;
	gap: 8px;

	background: #ede0d3;

	white-space: nowrap;

	color: #2b1d16;
	text-align: center;
	font-family: "OrticaLinear-Regular";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;

	border-radius: 1px;
}

.map-pois-poiLabelArrow {
	position: absolute;
	aspect-ratio: 14/28;
	height: 100%;

	top: 0%;

	fill: #ede0d3;

	background-position: center;
	background-size: cover;
	background-image: url(../../style/assets/map/arrow.svg);
}

.map-popupCenter {
	position: absolute;
	z-index: 20000;
	width: 80%;

	top: 50%;
	left: 50%;

	transform: translate(-50%, -50%);

	border-radius: 16px;

	background-color: #fdfaf7;
	box-shadow: 0px 46px 13px 0px rgba(37, 25, 19, 0), 0px 30px 12px 0px rgba(37, 25, 19, 0.03),
		0px 17px 10px 0px rgba(37, 25, 19, 0.09), 0px 7px 7px 0px rgba(37, 25, 19, 0.16),
		0px 2px 4px 0px rgba(37, 25, 19, 0.19);

	pointer-events: all;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.map-popupCenter-title {
	width: 90%;

	color: #2b1d16;
	text-align: center;

	margin: 1.2rem 0 0 0;

	font-family: "Geist";
	font-size: 24px;
	font-style: normal;
	font-weight: 700;
	line-height: 28px;
}

.map-popupCenter-text {
	color: #2b1d16;
	text-align: center;

	width: 80%;

	margin: 1.2rem 0 0 0;

	font-family: "Geist";
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px;
	letter-spacing: 0.32px;
}

.map-popupCenter-button {
	height: 42px;
	width: min(120px, 40%);

	margin: 1.2rem 0 1.2rem 0;

	line-height: min(16px, max(8vw, 8px));
	font-size: min(16px, max(8vw, 8px));
	font-weight: 500;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	border-radius: 20px;

	color: #fdfaf7;

	background-color: #2b1d16;
}

.map-popupCenter-buttonGray {
	width: 80%;

	margin: 0 0 1.6rem 0;

	color: #2b1d16;
	background-color: #2b1d1600;
}

.map-popupCenter-buttonBlack {
	width: 80%;

	margin: 1.2rem 0 0.8rem 0;

	color: #fdfaf7;
	background-color: #2b1d16;
}

.map-popupCenter-geolocIcon {
	width: 32px;
	height: 32px;

	margin: 1.6rem 0 0 0;

	background-repeat: no-repeat;
	background-size: 32px;
	background-position: center;
	background-image: url("../../style/assets/icons/target.svg");
}
